import React from "react"
import Layout from "../components/App/Layout"
import Navbar from "../components/App/Navbar"
import PageBanner from "../components/Common/PageBanner"
import Footer from "../components/App/Footer"
import ausimap from "../assets/images/about/graphic-australia-locations.svg"
import WhyChoose from "../components/AboutUs/WhyChoose"
import CoreValues from "../components/AboutUs/CoreValues"
import Achievements from "../components/AboutUs/Achievements"
import PathToOwnership from "../components/AboutUs/PathToOwnership"
import CTABanner from "../components/Common/CTABanner"
import SEO from "../components/App/SEO"
import { Link, graphql } from "gatsby"

function formatDate(date) {
  var d = new Date(date),
    month = "" + d.toLocaleString("default", { month: "long" }),
    day = "" + d.getDate(),
    year = d.getFullYear()

  if (day.length < 2) day = "0" + day

  return [day, month, year].join(" ")
}

const News = props => {
  const NewsArticles = props.data.allStrapiArticle.edges
  // const RssArticles = props.data.allFeedNewsArticles.edges

  const SortedNewsArticles = NewsArticles.sort(function (a, b) {
    var dateA = new Date(a.node.Date).getTime()
    var dateB = new Date(b.node.Date).getTime()
    return dateA < dateB ? 1 : -1
  })

  const featuredArticle = SortedNewsArticles[0]

  let AllNewsArticles = []

  for (let index = 0; index < NewsArticles.length; index++) {
    const NewsArticle = NewsArticles[index]
    if (NewsArticle.node.id !== featuredArticle.node.id) {
      let Article = {
        Id: NewsArticle.node.id,
        Title: NewsArticle.node.Title,
        Link: `/news/${NewsArticle.node.Slug}`,
        Image: NewsArticle.node.banner[0].url,
        Content: NewsArticle.node.subtitle,
        Date: NewsArticle.node.Date,
        Type: "Oxygen",
      }
      AllNewsArticles.push(Article)
    }
  }

  // for (let index = 0; index < RssArticles.length; index++) {
  //   const RssArticle = RssArticles[index]
  //   var lastYearDate = new Date(
  //     new Date().setFullYear(new Date().getFullYear() - 1)
  //   )
  //   var articleDate = new Date(RssArticle.node.pubDate)
  //   if (articleDate.getTime() < lastYearDate.getTime()) continue
  //   let Article = {
  //     Id: RssArticle.node.id,
  //     Title: RssArticle.node.title,
  //     Link: RssArticle.node.link,
  //     Content: RssArticle.node.contentSnippet,
  //     Date: RssArticle.node.pubDate,
  //     Type: "McGrath",
  //   }
  //   AllNewsArticles.push(Article)
  // }

  const SortedArticles = AllNewsArticles.sort(function (a, b) {
    var dateA = new Date(a.Date).getTime()
    var dateB = new Date(b.Date).getTime()
    return dateA < dateB ? 1 : -1
  })

  return (
    <Layout>
      <SEO
        title="News | Oxygen - Home Loans Made Simple"
        description="The latest news and articles from Oxygen Home Loans"
      />
      <Navbar />
      <div className="wr-news">
        <div className="page-title-area">
          <div className="container">
            <h1 className="section-title">News</h1>
          </div>
        </div>

        <div className="container">
          <div className="row">
            <div className="col-12 mb-5">
              <Link
                className="custom-card news-card news-feature"
                to={`/news/${featuredArticle.node.Slug}`}
              >
                <div className="feature-image">
                  <img src={featuredArticle.node.banner[0].url} />
                </div>
                <div>
                  <h3>{featuredArticle.node.Title}</h3>
                  <span className="date">
                    {formatDate(featuredArticle.node.Date)}
                  </span>
                  <p>{featuredArticle.node.subtitle}</p>

                  <Link
                    className="link"
                    to={`/news/${featuredArticle.node.Slug}`}
                  >
                    Read More
                  </Link>
                </div>
              </Link>
            </div>

            {SortedArticles.map((article, index) => {
              return (
                <div key={article.id} className="col-lg-4 col-md-6 mb-5">
                  <Link to={article.Link} className="custom-card news-card">
                    <h3>{article.Title}</h3>
                    <div className="article-info">
                      <span className={`news-source ${article.Type}`}>
                        {article.Type}
                      </span>
                      <span className="date">{formatDate(article.Date)}</span>
                    </div>
                    <p>{article.Content}</p>

                    <Link className="link" to={article.Link}>
                      Read More
                    </Link>
                  </Link>
                </div>
              )
            })}
          </div>
        </div>
        <CTABanner />
      </div>
      <Footer />
    </Layout>
  )
}

export const query = graphql`
  {
    allStrapiArticle {
      edges {
        node {
          id
          Author
          Slug
          Title
          subtitle
          Date
          banner {
            url
          }
        }
      }
    }
  }
`

// allFeedNewsArticles {
//   edges {
//     node {
//       title
//       contentSnippet
//       link
//       id
//       pubDate
//     }
//   }
// }

export default News
